*{
  background-color: rgb(27, 32, 48);
  color: rgb(241, 241, 243);
  font-family: Inter,Arial;
  font-weight: 400;
}

body{
  margin-top:0;
}
.navbar{
  display:flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1.5px solid rgba(235, 235, 239, 0.2);
  padding-top:12px;
  padding-bottom:12px;
  
  
}
.navbar h1{
  margin:0;
  padding-left:15px;
  
  font-size: 27px;
  color:rgb(255, 255, 255);
}

.navbar_questionIcon{
  display:none;
  background-color: rgb(27, 32, 48);
  padding-right:30px;
}

.drop{
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown{
  padding-top:30px;
  padding-bottom:30px;
  position: relative;
  display: inline-block;
}

.dropdown_icon{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right:7px;
}

.dropdown_text{
  display:flex;
}

/* Dropdown Button */
.dropdown_btn {
  margin: 0;
  display:block;
  font-weight: 700;
  letter-spacing: 0.015625rem;
  line-height: 123.5%;
  font-size:31px;
  background-color: none;
  color: white;
  padding-right:5px;
  border: none;
  cursor: pointer;
  
}


.dropdown_version{
  text-align: center;
}

/* Dropdown Content (Hidden by Default) */
.dropdown_content {
  display: none;
  position: absolute;
  min-width: 160px;
  z-index: 1;
  animation: fade-in 0.3s ease-in-out;
  border-radius: 4px;
  border: 1.5px solid rgb(56, 61, 81);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px, rgba(0, 0, 0, 0.1) 0px 2px 10px;
  outline: 0px;
}

/* Links inside the dropdown */
.dropdown_content a {
  color: rgb(255, 255, 255);
  background-color: rgb(56, 61, 81);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-top: 1px solid rgba(235, 235, 239, 0.08);

}


.dropdown_content button{
  color: rgb(255, 255, 255);
  padding:0;
  background-color: rgb(56, 61, 81);
  text-decoration: none;
  display: flex;
  border:none;
  align-items: center;
  cursor: pointer;
  width:100%;
}

.dropdown_content button:hover {
  background-color: rgba(56, 61, 81, 0.01);
}

.dropdown_content button p{
  color: rgb(255, 255, 255);
  background-color: inherit;
  text-decoration: none;
  font-size: 17px;
  padding-left:16px;

}


.dropdown_content a:hover {
  background-color: rgba(0, 0, 0, 0.01);
}

.dropdown_content_text{
  margin: 0px;
  font-weight: 500;
  letter-spacing: 0.00625rem;
  line-height: 1rem;
  font-size: 0.75rem;
  color: rgb(165, 168, 182);
  background-color: rgb(56, 61, 81);
  padding: 16px;
  border-bottom: 1px solid rgba(235, 235, 239, 0.08);

}

.dropdown_version_text{
  color: #fff;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 12px;
  padding-top:1px;
  padding-bottom:1px;
  background: linear-gradient(248.86deg, #B6509E 10.51%, #2EBAC6 93.41%);
  margin:0;
  width:20px;
  font-size:14px;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
  display: block;
  
}

/* Animation for fade-in effect */
@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
/* Animation for fade-out effect */
@keyframes fade-out {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

.search_div{
  display:flex;
  justify-content: center;
}

.search_div_input{
  font-size: large;
  background-color:rgb(56, 61, 81);
  border-radius: 4px;
  border: 1px solid rgba(235, 235, 239, 0.08);
  height:30px;
  width:527px;
  cursor: text;
  transition: border 0.2s;
  color: white;
  padding-left: 10px;
  font-size:15px;
  outline: none;
}
.search_div_search{
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
  cursor: pointer;
  position: relative;
  background: rgb(56, 61, 81);
  border-radius: 4px;
  border: 1px solid rgba(235, 235, 239, 0.08);
  display:flex;
  justify-content:center;
  align-items: center;
}

.search_div_search:hover{
  border: 1px solid rgba(235, 235, 239, 1);
}


.search_div_search_icon{
  width: 23px;
  height:23px;
  background: rgb(56, 61, 81);
}


.search_div_search[disabled] {
  color: rgb(146, 149, 161);
  cursor: not-allowed;
}

.loading{
  display:none;
  justify-content: center;
  align-items: center;
  padding-bottom:15px;
}

.loading_circle {
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  display: block;
}

.loading_text{
  padding-left:10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.headerInfo{
  padding-bottom:60px;
}

.info{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top:30px;
  padding-bottom:0px;
}

.info_container{
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.info_container_outer{

  padding-top:48px;
  padding-bottom:48px;

}

.info_container_top{
  display:grid;
  grid-template-rows: repeat(2, auto); /* 2 rows with dynamic heights */
  grid-auto-flow: column; /* Items fill in columns first */
  background-color: rgb(27, 32, 48);
  column-gap: 50px; /* Adjust the horizontal gap as needed */
}


.info_container_top p{
  background-color: rgb(27, 32, 48);
  font-weight: 400;
  letter-spacing: 0.029375rem;
  font-size: 17px;
  color: rgb(165, 168, 182);
  margin:0;
}

.info_container_bottom div{
  background-color: rgb(27, 32, 48);
  font-weight: 800;
  line-height: 133.4%;
  word-break:break-all;
}


.info_container_top div{
  display: flex;
}

.info_container_top_div{
  display: flex;
  position:relative;
  padding-bottom:5px;
}

.info_container_top_iconDiv{
  display:flex;
  align-items: center;
}

div.textbox {
  position:absolute;
  background-color: rgb(41, 46, 65);
  color: white;
  padding: 20px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5); /* Small shadow */
  border-radius: 5px;
  font-size: 13px;
  width:250px;
  bottom: 30px;

  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}



.info_container_top_netWorth_icon, .info_container_top_icon_healthFactor_icon, .info_container_top_icon_ltv_icon{
  width:15px;
  height:15px;
  padding-left:2px;
  cursor: pointer;
 
}

.info_container_bottom_netWorth_symbol{
  color: rgb(165, 168, 182);
  padding-right: 2px;
}

.info_container_bottom_ltv_symbol{
  color: rgb(165, 168, 182);
  padding-left:2px;
}

.info_container_bottom_ltv, .info_container_bottom_healthFactorValue, .info_container_bottom_netWorth_symbol, .info_container_bottom_netWorth_value, .info_container_bottom_ltv_symbol{
  font-size:21px;
  font-weight: 800;
  line-height: 133.4%;
  font-size: 25px;
  word-break:break-all;
}

.b_s_container_borrow_btn, .b_s_container_supply_btn{
  font-size:large;
  background: rgb(56, 61, 81);
  border-radius: 4px;
  border: 1px solid rgba(235, 235, 239, 0.08);
  padding:5px;
  width:100px;
  cursor: pointer;
  transition: border 0.2s;
  
}
.b_s_container_borrow_btn[disabled], .b_s_container_supply_btn[disabled] {
  color: rgb(146, 149, 161);
  cursor: not-allowed;
}

.b_s_container_supply_btn:hover, .b_s_container_borrow_btn:hover{
  border: 1px solid rgba(255, 255, 255, 0.8);
}
.switchSupplyAndBorrow{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom:20px;
}
.switchSupplyAndBorrow_container{
  background-color:rgb(56, 61, 81);
  display: flex;
  justify-content: center;
  align-items: center;
  padding:5px;
  border-radius: 4px;
  border: 1px solid rgba(235, 235, 239, 0.08);
  
}

.switchSupplyAndBorrow_button{
  width:150px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  background-color: inherit;
  color: rgba(235, 235, 239, 0.56);
  border-radius: 4px;
}


.switchSupplyAndBorrow_button p {
  color:rgb(140, 149, 181);
  background-color:rgb(56, 61, 81);
}



.switchSupplyAndBorrow_button.active p {
  background-image: linear-gradient(90deg, rgb(46, 186, 198) 16%, rgba(182, 80, 158, 1) 91%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.switchSupplyAndBorrow_button.active {
  background-color: rgb(255, 255, 255);
}



.supply_button_text, .borrow_button_text{
  margin:0;
}

.assets{
  display: flex;
  justify-content: center;
}

.assets_supply{
  padding:10px;
  padding-right:20px;
  background-color: rgb(41, 46, 65);
  border: 1px solid rgba(235, 235, 239, 0.08);
  border-radius: 4px;
  min-height:125px;
  margin-right: 15px;
  width:40%;
  padding-bottom:0;
  
  
}

.assets_supply_top, .assets_borrow_top{
  display:flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(41, 46, 65);
}

.assets_borrow{
  padding:10px;
  padding-right:20px;
  background-color: rgb(41, 46, 65);
  border: 1px solid rgba(235, 235, 239, 0.08);
  border-radius: 4px;
  min-height:125px;
  width:40%;
  padding-bottom:0;
}


.assets_supply_top_header, .assets_borrow_top_header{
  padding:10px;
  background-color: rgb(41, 46, 65);
  font-size: 20px;
}

.assets_supply_nothing, .assets_borrow_nothing{
  padding:10px;
  background-color: rgb(41, 46, 65);
  font-size: 14px;
  color:rgb(165, 168, 182);
}

.assets_supply_header, .assets_borrow_header{
  display: none;
  grid-template-columns:repeat(4, 1fr) 0.4fr;
  grid-gap: 10px;
  font-size: small;
  justify-items: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  background-color: rgb(41, 46, 65);
  margin-left:-10px;
  margin-right:-20px;
  padding-top:35px;

}

.assets_supply_header h3{
  margin:0;
  background-color: rgb(41, 46, 65);
  font-size: 0.75rem;
  color:rgb(165, 168, 182);
}

.assets_borrow_header h3{
  margin:0;
  background-color: rgb(41, 46, 65);
  font-size: 0.75rem;
  color:rgb(165, 168, 182);
}


.modal_supply, .modal_borrow{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px); 
  display:flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; 

  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0s linear 0.3s;
  
}

.modal_supply.visible {

  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s;
}

.modal_borrow.visible {

  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s;
}

.modal_supply_content, .modal_borrow_content{
  width: 50%;
  height: 85%;
  background-color: rgb(41, 46, 65);
  border: 1px solid rgba(235, 235, 239, 0.08);
  border-radius: 10px;
  padding:10px;
  overflow: auto;
  
}

.modal_supply_content_header, .modal_borrow_content_header{
  display:flex;
  background-color: rgb(41, 46, 65);
  justify-content: space-around;
  margin-bottom: 20px;
  align-items: center;
  margin-top:10px;
}

.modal_supply_content_header p{
  background-color:inherit;
  margin:0;
  padding:10px;
  font-size: x-large;
}

.modal_borrow_content_header p{
  background-color:inherit;
  margin:0;
  padding:10px;
  font-size: x-large;
}

.modal_supply_content_header_btn, .modal_borrow_content_header_btn{
  background-color:rgb(41, 46, 65);
  font-size:12px;
  background: rgb(56, 61, 81);
  border-radius: 4px;
  border: 1px solid rgba(235, 235, 239, 0.08);
  transition: border 0.2s;
  padding:10px;
  cursor:pointer;
}

.modal_supply_content_header_btn:hover, .modal_borrow_content_header_btn:hover{

  border: 1px solid rgba(235, 235, 239);

}


.modal_supply_content_assets_labels, .modal_borrow_content_assets_labels{
  background-color:rgb(41, 46, 65);
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: center;
  border-bottom: 1.2px solid rgba(235, 235, 239, 0.3);
  margin-left: -10px;
  margin-right: -10px;
  
}
.modal_supply_content_assets_labels p{
  font-size: 0.75rem;
  color:rgb(165, 168, 182);
}

.modal_borrow_content_assets_labels p{
  font-size: 0.75rem;
  color:rgb(165, 168, 182);
}


.modal_supply_content_assets_grid, .modal_borrow_content_assets_grid{
  margin-left: -10px;
  margin-right: -10px;
}

.modal_supply_content_assets p{
  background-color:rgb(41, 46, 65);
  
}


.modal_borrow_content_assets p{
  background-color:rgb(41, 46, 65);
  
}

.modal_supply_content_scrollable, .modal_borrow_content_scrollable {
  overflow: auto;
  background-color:rgb(41, 46, 65);
  height: 500px;
  border-bottom: 1.2px solid rgba(235, 235, 239, 0.3);
}

.modal_supply_content_scrollable_list, .modal_borrow_content_scrollable_list{
  background-color:rgb(41, 46, 65);
  list-style-type: none;
  padding:0;
  margin:0;
}

.modal_supply_content_scrollable_list li{
  background-color:rgb(41, 46, 65);
}

.modal_borrow_content_scrollable_list li{
  background-color:rgb(41, 46, 65);
}

.modal_supply_content_scrollable_list div{
  background-color:rgb(41, 46, 65);
  display:grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid rgba(235, 235, 239, 0.08);
  padding-top: 30px;
  padding-bottom:30px;
  justify-items: center;
  align-items: center;
}

.modal_borrow_content_scrollable_list div{
  background-color:rgb(41, 46, 65);
  display:grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid rgba(235, 235, 239, 0.08);
  padding-top: 30px;
  padding-bottom:30px;
  justify-items: center;
  align-items: center;
}

.modal_supply_content_scrollable_list div button{
  font-size:medium;
  background-color: rgb(56, 61, 81);
  border-radius: 4px;
  border: 1px solid rgba(235, 235, 239, 0.08);
}

.modal_borrow_content_scrollable_list div button{
  font-size:medium;
  background-color: rgb(56, 61, 81);
  border-radius: 4px;
  border: 1px solid rgba(235, 235, 239, 0.08);
}

.modal_supply_content_scrollable_list div button:hover{
  border: 1px solid rgba(235, 235, 239, 0.8);
}

.modal_borrow_content_scrollable_list div button:hover{
  border: 1px solid rgba(235, 235, 239, 0.8);
}

.modal_borrow_content_scrollable_list li{
  background-color:rgb(41, 46, 65);
}

.modal_supply_content_assets_div, .modal_borrow_content_assets_div{
  background-color:rgb(41, 46, 65);
  display: flex;
  justify-content: center;
  align-items: center;
  height:75px;
  /*TODO: Apply to borrow side*/
}

.modal_supply_content_assets_bottom, .modal_borrow_content_assets_bottom{
  background-color:rgb(41, 46, 65);
  padding-top:10px;
}

.modal_supply_content_assets_bottom_btn{
  background-color:rgb(41, 46, 65);
  font-size:20px;
  background: rgb(56, 61, 81);
  border-radius: 4px;
  border: 1px solid rgba(235, 235, 239, 0.08);
  transition: border 0.2s;
  padding:10px;
}

.modal_supply_content_assets_bottom_btn:hover{
  border: 1px solid rgba(235, 235, 239, 0.8);
}

.modal_borrow_content_assets_bottom_btn{
  background-color:rgb(41, 46, 65);
  font-size:20px;
  background: rgb(56, 61, 81);
  border-radius: 4px;
  border: 1px solid rgba(235, 235, 239, 0.08);
  transition: border 0.2s;
  padding:10px;
}


.modal_borrow_content_assets_bottom_btn:hover{
  border: 1px solid rgba(235, 235, 239, 0.8);
}


.assets_supply_tokens_list{
  padding:0;

}

.assets_borrow_tokens_list{
  padding:0;

}

.supply_outer_div, .borrow_outer_div{
  background-color:rgb(41, 46, 65);
  display: grid;
  grid-template-columns: repeat(4, 1fr) 0.4fr;
  grid-gap: 10px;
  justify-items: center;
  align-items: center;
  margin-left:-10px;
  margin-right:-20px;
  border-top: 1px solid rgba(235, 235, 239, 0.08);
  padding-top:15px;
  padding-bottom:15px;
}

.supply_buttons, .borrow_buttons{
  width: 12px; 
  height: 12px; 
  background-image: url('./images/x-symbol.svg'); 
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer; 
  border:none;
  background-color:rgb(41, 46, 65);
  
}


.supply_labels{
  display:none;
}


@media screen and (max-width: 450px) {
  .supply_outer_div, .borrow_outer_div {
    grid-template-columns: repeat(1, 1fr); /* 2 columns on smaller screens */
    grid-gap:20px;
  }
  .supply_outer_div p{
    margin:0;
  }

  .borrow_outer_div p{
    margin:0;
  }
}


.assets_supply_tokens_list li{
  background-color:rgb(41, 46, 65);
  display:flex;
}

.assets_supply_tokens_list input{
  width:100px;
  background-color:rgb(56, 61, 81);
  border: 1px solid rgba(235, 235, 239, 0.08); /* Set the border color */
  border-radius:4px;
  
}

.assets_supply_tokens_list input:hover{
  border: 1px solid rgba(255, 255, 255, 0.8); /* Set the border color */
}

.assets_supply_tokens_list p{
  background-color:rgb(41, 46, 65);
  font-size: 14px;
}


.assets_borrow_tokens_list li{
  background-color:rgb(41, 46, 65);
  display:flex;
}

.assets_borrow_tokens_list input{
  width:100px;
  background-color:rgb(56, 61, 81);
  border: 1px solid rgba(235, 235, 239, 0.08); /* Set the border color */
  border-radius:4px;
}

.assets_borrow_tokens_list input:hover{
  border: 1px solid rgba(255, 255, 255, 0.8); /* Set the border color */
}

.assets_borrow_tokens_list p{
  background-color:rgb(41, 46, 65);
  font-size: 14px;
  text-align: left;
}

.assets_borrow_info{
  display:none;
  background-color:rgb(41, 46, 65);
}


.assets_borrow_info_box_left{
  padding-right:10px;
  background-color:rgb(41, 46, 65);
  padding-left:10px;
}

.assets_borrow_info_box_left_inner{
  display:flex;
  padding: 2px 4px;
  border: 1px solid rgba(235, 235, 239, 0.08);
  border-radius:4px;
  background-color:rgb(41, 46, 65);
  
}
.assets_supply_info_box{
  display:flex;
  background-color:rgb(41, 46, 65);
  padding-left:10px;
}


.assets_supply_info_box_left{
  display:none;
  padding: 2px 4px;
  border: 1px solid rgba(235, 235, 239, 0.08);
  border-radius:4px;
  background-color:rgb(41, 46, 65);
}

.assets_borrow_info_box_left p, .assets_supply_info_box_left p{
  margin:0;
  background-color:rgb(41, 46, 65);
  font-weight: 400;
  letter-spacing: 0.029375rem;
  font-size: 1rem;
  color: rgb(165, 168, 182);
}

.info_container_bottom_totalBorrowed, .info_container_bottom_totalSupplied{
  background-color:rgb(41, 46, 65);
  letter-spacing: 0.029375rem;
  font-size: 1rem;
  font-weight:bold;
  word-break:break-all;
}


.values{
  margin-top:20px;
  display: flex;
  justify-content: center;
}
.values_container{
  padding:10px;
  padding-right:0px;
  padding-left:0px;
  padding-bottom:0;
  background-color: rgb(41, 46, 65);
  border: 1px solid rgba(235, 235, 239, 0.08);
  border-radius: 4px;
  
}
.values_container_empty{
  padding:10px;
  background-color: rgb(41, 46, 65);
  font-size: 14px;
  color:rgb(165, 168, 182);
  padding-bottom:30px;
}
.values_container_list{
  background-color:rgb(41, 46, 65);
  padding:0;
}

.values_container_list li{
  background-color:rgb(41, 46, 65);
  list-style-type: none;
  
}


.outer_div{
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-gap: 10px;
  font-size: smal;
  justify-items: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  background-color: rgb(41, 46, 65);
  margin-left:-10px;
  margin-right:-10px;
  padding-top:35px;
  padding-bottom:35px;
  border-top: 1px solid rgba(235, 235, 239, 0.08);
}

.outer_div p{
  background-color: rgb(41, 46, 65);

}

.slider_input{
  width:100%;
  
}

.slider_outer_div{
  width:100%;
  background-color: rgb(41, 46, 65);

  
}
.slider_div_top{
  display: block;

  background-color: rgb(41, 46, 65);
}

.slider_outer_div p{
  margin:0;
  text-align: center;
}

#slider_div_top_min{
  padding:0;
}

.slider_div_bottom{
  display:flex;
  justify-content: space-between;
  background-color: rgb(41, 46, 65);
  
}

.slider_div_bottom p {
  background-color: rgb(41, 46, 65);
  margin:0;
}



.values_container_title{
  padding:10px;
  background-color: rgb(41, 46, 65);
  font-size: 20px;
}


.values_container_header{
  display: none;
  grid-template-columns: 1fr 2fr 1fr;
  grid-gap: 10px;
  font-size: small;
  justify-items: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  background-color: rgb(41, 46, 65);
  margin-left:-10px;
  margin-right:-10px;
  padding-top:35px;
}

.values_container_header h3{
  margin:0;
  background-color: rgb(41, 46, 65);
  font-size: 0.75rem;
  color:rgb(165, 168, 182);
}

.bottom_info{
  padding-top:40px;
  display: grid;
  place-items: center;
  text-align: center;
  border-top: 1.5px solid rgba(235, 235, 239, 0.2);
}

.bottom_socials{
  list-style: none; /* Remove the default bullet points */
  padding: 0; /* Remove any default padding */
  margin: 0; /* Remove any default margin */
}

.bottom_socials li {
  margin-bottom: 12px; /* Adjust the vertical spacing between list items */
}

.bottom_socials li a {
  text-decoration: none; /* Remove underline from links */
  color: #ffffff; /* Set the color of the links */
}

.bottom_socials li a:hover {
  color: #e3e3e3; /* Set the color of the links on hover */
}

.bottom_info_address{
  width:175px;

}

.bottom_info_address p {
  margin-bottom:8px;
}

.bottom_info_address button{
  margin:0;
  border:none;
  cursor: pointer;
  padding:0;
  justify-content: center;
  
}
.bottom_info_address_div{
  display:flex;
  /*justify-content: space-between;*/
  justify-content:center;
}

.bottom_info_address_div_divider{
  display:none;
  margin:0;
  padding-left:5px;
  padding-right:5px;
  font-size:20px;
}

.bottom_info_address div{
  display:flex;
  align-items: center;
}

.bottom_info_address_div_symbol{
  font-size:20px;
  margin:5px;
  margin-left:0px;

}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}


input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.threshold_input{
  padding: 5px;
  background-color: rgb(56, 61, 81);
  border: 1px solid rgba(235, 235, 239, 0.08);
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.2s;
}
.threshold_input:focus {
  border-color: #ffffff;
}

.bottom_info_address_icon{
  width:20px;
  height:20px;
}

.error-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display:none;
  background-color: rgb(87, 10, 10);
  border: 1px solid rgba(235, 235, 239, 0.08);
  color: #ffffff;
  padding: 10px;
  border-radius: 4px;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}
.bottom_errorContainer{
  position: fixed;
  bottom: 20px;
  right: 20px;
  display:none;
  background-color: rgb(56, 61, 81);
  border: 1px solid rgba(235, 235, 239, 0.08);
  border-radius:4px;
  color: #ffffff;
  padding: 10px;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}
